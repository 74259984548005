<i18n>
ru:
  hours: '{number} ч'
  minutes: '{number} мин'
  seconds: '{number} сек'
ua:
  hours: '{number} год'
  minutes: '{number} хвилин'
  seconds: '{number} сек'
us:
  hours: '{number} hours'
  minutes: '{number} minutes'
  seconds: '{number} seconds'
</i18n>

<template>{{ time }}</template>

<script setup lang="ts">
const { timeMs = 0 } = defineProps<{
  timeMs?: number
}>()

const { makeReadableTimeFromMs } = useDateTime()

const time = computed<string>(() => makeReadableTimeFromMs(timeMs))
</script>
